import Konva from 'konva';
import Graph from './graph/graph';
import { createNode } from './node/node'
import { createGraphFromJSON, saveGraphAsJSON } from './graph/graph.json.handling';
import { runAlgorithm, runAlgorithmLCA, runAlgorithmPRBM, selectNode } from './algorithms/execute.alg';

import '../../node_modules/@fortawesome/fontawesome-free/js/all.js';
import 'bulma/css/bulma.css'

var graph;

/**
 * DOCUMENT EVENT LISTENERS
 */

document.addEventListener("DOMContentLoaded", function () {
    main();
});

document.getElementById('uploadFile').onchange = function () {
    let files = document.getElementById('uploadFile').files;

    if (files.length <= 0) { return false; }

    let fr = new FileReader();

    fr.onload = function (e) {
        main();
        createGraphFromJSON(graph, e.target.result);
    }

    fr.readAsText(files.item(0));
};


/**
 * INITIALIZE GRAPH AND STAGE
 */

function main() {
    graph = new Graph();
    document.getElementById('result-tile').style.display = 'none';

    var width = document.getElementById('container').clientWidth;
    var height = document.getElementById('container').clientHeight;

    graph.countNodes = 0;

    graph.stage = new Konva.Stage({
        container: 'container',
        width: width,
        height: height
    });

    graph.stage.on('contentContextmenu', function(e) {
        e.evt.preventDefault();
    });

    graph.layer = new Konva.Layer();
    graph.arrowLayer = new Konva.Layer();

    graph.stage.add(graph.layer);
    graph.stage.add(graph.arrowLayer);
}


/**
 * WINDOW FUNCTIONS CALLED FROM HTML 
 */
window.closeWelcomeModal = function() { closeWelcomeModal(); }

window.addNode = function() { createNode(graph); }

window.clearStage = function () { main(); }

window.closeModal = function () { document.getElementById('error-modal').classList.remove('is-active'); }

window.runAlgorithm = function () { runAlgorithm(graph); }

window.runAlgorithmLCA = function () { runAlgorithmLCA(graph); }

window.runAlgorithmPRBM = function () {runAlgorithmPRBM(graph); }

window.saveGraph = function() {

    let obj = saveGraphAsJSON(graph);
    let data = "text/json;charset=utf-8,"
    + encodeURIComponent(JSON.stringify(obj));
    let a = document.createElement('a');
    a.href = 'data:' + data;
    a.download = 'my-graph.json';
    a.click();

}

window.getRadio = function (event) {
  event.preventDefault();
  var radio = document.querySelector('input[name="node"]:checked').value;
  selectNode(radio);
  runAlgorithmLCA(graph);
  modal.style.display = "none";
}

// Get the modal
var modal = document.getElementById("lcaModal");
        
// Get the button that opens the modal
var btn = document.getElementById("myBtn");

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

function closeWelcomeModal() {
  var element = document.getElementById("welcomeModal");
  element.classList.remove("active");
  document.body.classList.remove("hideScrollbar");
}

// When the user clicks the button, open the modal 
btn.onclick = function() {

document.getElementById("radioBtnDiv").innerHTML = "";

  for (let i = 0; i < graph.countNodes; i++) {
    document.getElementById("radioBtnDiv").innerHTML += 
    `
    <input type="radio" class="radioClss" id="radioBtn`+i+`" name="node" value="`+i+`">
    <label for="radioBtn`+i+`">Node `+i+`</label><br></br>
    `
  }
  modal.style.display = "block";
}

// When the user clicks on <span> (x), close the modal
span.onclick = function() {
  modal.style.display = "none";
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
}

// PRBM - Modal
window.runAlgorithmPRBMWithPara = function (event) {
  event.preventDefault();
  var objectiveFunctionData = {};
  var objectiveFunction = document.querySelector('input[name="objectiveFunction"]:checked').value;
  console.warn("objectiveFunction",objectiveFunction)
  if(objectiveFunction == "RI") {
    var cp = document.querySelector('input[id="cp-value"]').value;
    if(cp.length > 0) {
      objectiveFunctionData.cp = cp;
    } else {
      alert("Please define CP Value!")
    }
  } else if(objectiveFunction == "RD") {
    var cd = document.querySelector('input[id="cd-value"]').value;
    var y = document.querySelector('input[id="y-value"]').value;
    if(cd.length > 0 && y.length > 0) {
      objectiveFunctionData.cd = cd;
      objectiveFunctionData.y = y;
    } else {
      alert("Please define CD and Y Value!")
    }
  } else if(objectiveFunction == "RL") {
    console.log("");
  } else {
    console.warn("Please select objectiveFunction")
  }
  var priorityRule = document.querySelector('input[name="priorityRule"]:checked').value;
  console.warn("priorityRule",priorityRule)
  runAlgorithmPRBM(graph,objectiveFunction,priorityRule,objectiveFunctionData)
  PRBMmodal.style.display = "none";
}

// Get the modal
var PRBMmodal = document.getElementById("PRBM-Modal");

// Get the button that opens the modal
var PRBMbtn = document.getElementById("PRBM-Btn");

// Get the <span> element that closes the modal
var PRBMspan = document.getElementsByClassName("PRBM-close")[0];

// When the user clicks on the button, open the modal
PRBMbtn.onclick = function() {
  PRBMmodal.style.display = "block";
}

// When the user clicks on <span> (x), close the modal
PRBMspan.onclick = function() {
  PRBMmodal.style.display = "none";
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
  if (event.target == PRBMmodal) {
    PRBMmodal.style.display = "none";
  }
}