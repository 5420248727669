import { createTable, createTableLCA, createTablesPRBM, checkValidityOfGraph, getRessourcePerTimeUnitPerNode} from './alg';
import Chart from 'chart.js/auto';

var selectedNode = -1;
export function selectNode(node) 
{
    selectedNode = node;
}

export function runAlgorithm(graph) {

    let errorObj = checkValidityOfGraph(graph);
    let invalidArrows = errorObj.arrows;
    let invalidDurations = errorObj.durations;
    let invalidResources = errorObj.resources;

    if (invalidArrows.length > 0 || invalidDurations.length > 0) {
        invalidArrows.forEach(arrow => {
            graph.findKonvaArrowById(arrow).attrs.stroke = 'red';
            graph.findKonvaArrowById(arrow).attrs.fill = 'red';
        });

        invalidDurations.forEach(id => {
            graph.findKonvaTextById(id).attrs.fill = 'red';
        });

        invalidResources.forEach(id => {
            graph.findKonvaTextById(id).attrs.fill = 'red';
        });

        document.getElementById('error-modal').classList.add('is-active');
        graph.arrowLayer.draw();
        graph.layer.draw();
        return;
    }

    resetResultsHtml();

    let out = "";
    let htmlResultTable = document.getElementById('result-table');
    let table = createTable(graph);

    out += "<tr>";
    out += "<th>i</th>"

    for (let i = 0; i < table.ES.length; i++) {
        out += `<th>${i}</th>`;
    }
    out += "</tr>";

    Object.keys(table).forEach(function (key) {
        let temp = "";
        temp += "<tr>";
        temp += `<td>${key}<sub>i</sub></td>`;
        for (let i = 0; i < table[key].length; i++) {
            temp += `<td>${table[key][i]}</td>`;
        }
        temp += "</tr>";
        out += temp;
    });

    htmlResultTable.innerHTML = out;
    document.getElementById('result-tile').style.display = 'block';
}

// Run LabelCorrectingAlgorithm
export function runAlgorithmLCA(graph) {
// If a part of graph is not valid, mark red and show popup
    let errorObj = checkValidityOfGraph(graph);
    let invalidArrows = errorObj.arrows;
    let invalidDurations = errorObj.durations;
    let invalidResources = errorObj.resources;

    if (invalidArrows.length > 0 || invalidDurations.length > 0) {
        invalidArrows.forEach(arrow => {
            graph.findKonvaArrowById(arrow).attrs.stroke = 'red';
            graph.findKonvaArrowById(arrow).attrs.fill = 'red';
        });

        invalidDurations.forEach(id => {
            graph.findKonvaTextById(id).attrs.fill = 'red';
        });

        invalidResources.forEach(id => {
            graph.findKonvaTextById(id).attrs.fill = 'red';
        });

        document.getElementById('error-modal').classList.add('is-active');
        graph.arrowLayer.draw();
        graph.layer.draw();
        return;
    }
// Reset old results
    resetResultsHtml();

    let out = "";
    let htmlResultTable = document.getElementById('result-table');

    document.getElementById('resultHeading').innerHTML += ": Label Correcting Algorithm (selected start node: "+selectedNode+")";
    
    let table = createTableLCA(graph);
    let iterations = table.LCATable[selectedNode].iterationQ.length;
      
    out += "<th class='lca-iterations' style='width: 40px'>Iteration</th>"
    for (let i = 0; i < iterations; i++) {
        out += `<th class="lca-iterations">${i}</th>`; 
    }
    out += "</tr>";
   
    out += "<th class='lca-iterations'>i</th>"
    for (let i = 0; i < iterations; i++) {
        out += `<th class="lca-header">d<sub>i</sub> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; p<sub>i</sub></th>`; 
    }
    out += "</tr>";

    for (let i = 0; i < table.LCATable.length; i++) {
        out += "<tr>"
        out += `<th class="lca-iterations">${i}</th>`;     
       
        for (let j = 0; j < iterations; j++) {

            if (table.LCATable[selectedNode].iterationD[j][i] === Number.NEGATIVE_INFINITY) {
                out += `<td class="lca-values">`+ `-&infin;` +` &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; `+ table.LCATable[selectedNode].iterationP[j][i]  + `</td>`;
            }

                else {out += `<td class="lca-values">`+ table.LCATable[selectedNode].iterationD[j][i] +` &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; `+ table.LCATable[selectedNode].iterationP[j][i]  + `</td>`; }  
               
        } 
    }
    out += "</tr>";
    out += "<th class='lca-q'>Q</th>"
    for (let i = 0; i < iterations; i++) {
        if(i == iterations-1) {
            out += `<th class="lca-q">{}</th>`; 
        } else {
            out += `<th class="lca-q">{`+ table.LCATable[selectedNode].iterationQ[i] +`}</th>`; 
        }     
    }
    out += "</tr>";

    htmlResultTable.innerHTML = out;
    document.getElementById('result-tile').style.display = 'block';
}

//Run Priority Rule Based Method
export function runAlgorithmPRBM(graph,objectiveFunction,priorityRule,objectiveFunctionData) {

    let errorObj = checkValidityOfGraph(graph);
    let invalidArrows = errorObj.arrows;
    let invalidDurations = errorObj.durations;
    let invalidResources = errorObj.resources;

    if (invalidArrows.length > 0 || invalidDurations.length > 0) {
        invalidArrows.forEach(arrow => {
            graph.findKonvaArrowById(arrow).attrs.stroke = 'red';
            graph.findKonvaArrowById(arrow).attrs.fill = 'red';
        });

        invalidDurations.forEach(id => {
            graph.findKonvaTextById(id).attrs.fill = 'red';
        });

        invalidResources.forEach(id => {
            graph.findKonvaTextById(id).attrs.fill = 'red';
        });

        document.getElementById('error-modal').classList.add('is-active');
        graph.arrowLayer.draw();
        graph.layer.draw();
        return;
    }

    const tables = createTablesPRBM(graph,objectiveFunction,priorityRule,objectiveFunctionData);  
    console.log("PRBM tables",tables);

    resetResultsHtml();
    
    let out = "";
    document.getElementById('result-tile-heading').innerHTML = "Results: Priority-Rule-Based-Method";
    let userInputText = '(' + objectiveFunction + ')' + (Object.keys(objectiveFunctionData).length === 0 ? '' : JSON.stringify(objectiveFunctionData)) + ', ' + priorityRule;
    document.getElementById('user-preferences').innerHTML = "Your input:" + '<p style="font-style:italic">'+userInputText+'</p>';

    let htmlResult = document.getElementById('result-tile');

    let chartLabels = [];
    let chartDatasets = [];

    for (let i = 0; i < tables.length; i++) {
        if(i > 0) {
            out += '<p style="margin-top:20px; margin-left:20px; margin-bottom: 10px; font-size: 20px;"><b>Iteration '+ i +'</b></p>';
            out += '<p style="margin-left:20px; margin-bottom: 5px;">Nextnode '+ tables[i-1].nextNode +'</p>';
            out += '<p style="margin-left:20px; margin-bottom: 5px;">DecisionPoints '+ tables[i].decisionPoints +'</p>';
            out += '<p style="margin-left:20px; margin-bottom: 5px;">Starttime '+ tables[i].startTimeNextNode +'</p>';
            out += '<p style="margin-left:20px; margin-bottom: 20px;">Additional Costs '+ tables[i].additionalCosts +'</p>';
        }
        out += '<p id="resultHeading" class="panel-heading">'+ (i == 0 ? 'Initialization phase' : 'ES- / LS-Update') +'</p>';
        out +='<div class="panel-block">'
        
        let table = '<table id="result-table" class="table is-striped">'
        table += "<tr>";
        table += "<th>i</th>";
    
        for (let j = 0; j < tables[i].basicMetrics.ES.length; j++) {
            table += `<th>${j}</th>`;
        }
        table += "</tr>";
    
        Object.keys(tables[i].basicMetrics).forEach(function (key) {
            let temp = "";
            temp += "<tr>";
            temp += `<td>${key}<sub>i</sub></td>`;
            for (let j = 0; j < tables[i].basicMetrics[key].length; j++) {
                temp += `<td>${tables[i].basicMetrics[key][j]}</td>`;
            }
            temp += "</tr>";
            table += temp;
        });
        table += '</table>'

        out += table;

        out += '</div>'
        out += `<div id="tableFooter">
        <div style="padding-top:20px; padding-bottom:10px; font-weight:bold; margin-left:20px;"><p style="display:inline; font-style:italic;">C</p>: {`+tables[i].scheduledActivities.c+`}</div>
        <div style="padding-top:10px; padding-bottom:10px; font-weight:bold; margin-left:20px;"><p style="text-decoration:overline; display:inline; font-style:italic;">C</p>: {`+tables[i].scheduledActivities.c_quer+`}</div>
        <div style="padding-top:10px; padding-bottom:20px; font-weight:bold; margin-left:20px;"><p style="display:inline; font-style:italic;">S<sub>C</sub></p>: {`+tables[i].scheduledActivities.SC+`}</div>
        </div>`

        if(i == tables.length-1) {
            out += `<div style="margin-top:20px; margin-left:20px; font-size: 18px;"><b>Objective function value: `+tables[i].objectiveFunctionValue+`</b></div>`
            out += `<div style="margin-top:20px; margin-left:20px; margin-bottom:20px; font-size: 18px;"><b>Optimal Schedule <p style="display:inline; font-style:italic;">S<sub>C</sub></p>: {`+tables[i].scheduledActivities.SC+`}</b></div>`
            
            let ressourcePerTimeUnitPerNode = getRessourcePerTimeUnitPerNode(graph,tables[i].scheduledActivities.c,tables[i].scheduledActivities.SC,tables[0].basicMetrics.RP,tables[0].basicMetrics.LS);
            
            for (let t=0;t<ressourcePerTimeUnitPerNode[0].length+1;t++) {
                chartLabels.push(t);
            }
            Object.keys(ressourcePerTimeUnitPerNode).forEach(function (node) {
                let chartDatasetObject = {
                    label: "Node "+ node,
                    data: ressourcePerTimeUnitPerNode[node],
                    barPercentage: 1.0,
                    categoryPercentage: 1.0,
                    backgroundColor: nodeColors[node]
                }
                chartDatasets.push(chartDatasetObject);
            });
        }
        
    }
    htmlResult.innerHTML = out;
    
    htmlResult.style.display = 'block';

    const ctx = document.getElementById('myChart');
    document.getElementById('chartHeading').innerHTML = "Resource profile";

    const data = {
    labels: chartLabels,
    datasets: chartDatasets
    };

    const config = {
        type: 'bar',
        data: data,
        options: {
          plugins: {
            title: {
              display: false
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
            },
            y: {
                stacked: true
            }
          }
        }
      };

    let resChart = new Chart(ctx, config);

    const visibleMetas = resChart.getSortedVisibleDatasetMetas();
    let offset = visibleMetas[0].data[0].width / 2
    resChart.options.scales.x.ticks.labelOffset = -offset;
    resChart.update();
}

function resetResultsHtml() {

    document.getElementById('user-preferences').innerHTML = "";

    document.getElementById('result-tile-heading').innerHTML = "";

    document.getElementById('result-tile').innerHTML = `
    <p id="resultHeading" class="panel-heading">result</p>
    <div class="panel-block">
        <table id="result-table" class="table is-striped"></table>
    </div>
    <div id="tableFooter"></div>`;

    document.getElementById('chartDiv').innerHTML = `
        <p id="chartHeading" class="panel-heading"></p>
        <canvas id="myChart"></canvas>
    `;
}

const nodeColors = [
    "rgba(229, 153, 153, 0.5)",
    "rgba(153, 229, 153, 0.5)",
    "rgba(153, 153, 229, 0.5)",
    "rgba(229, 153, 229, 0.5)",
    "rgba(229, 229, 153, 0.5)",
    "rgba(153, 229, 229, 0.5)",
    "rgba(204, 204, 204, 0.5)",
    "rgba(153, 204, 229, 0.5)",
    "rgba(229, 204, 204, 0.5)",
    "rgba(204, 229, 204, 0.5)",
    "rgba(204, 204, 229, 0.5)",
    "rgba(255, 255, 119, 0.5)",
    "rgba(204, 229, 229, 0.5)",
    "rgba(229, 229, 204, 0.5)",
    "rgba(189, 204, 174, 0.5)",
    "rgba(255, 204, 204, 0.5)",
    "rgba(204, 255, 204, 0.5)",
    "rgba(204, 204, 255, 0.5)",
    "rgba(255, 204, 255, 0.5)",
    "rgba(229, 204, 229, 0.5)",
    "rgba(255, 157, 204, 0.5)",
    "rgba(204, 255, 255, 0.5)",
    "rgba(229, 179, 214, 0.5)",
    "rgba(214, 229, 179, 0.5)",
    "rgba(179, 214, 229, 0.5)",
    "rgba(214, 179, 229, 0.5)"
  ];