
export function checkValidityOfGraph(graph){

    // get weights that are not numbers or undefined
    let arrowWeightsNotNumbers =
    graph.weights
    .filter(wei => isNaN(parseInt(wei.number)))
    .map(wei => wei.arrow);

    // every arrow needs to be connected
    let unconnectedArrow =
    graph.connections
    .filter(con => con.end == undefined)
    .map(con => con.arrow);

    // every duration that is not a number
    let durationsNotNumbers =
        graph.durations
            .filter(dur => isNaN(parseInt(dur.number)))
            .map(dur => dur.id);

    // every duration that is less than 0
    let durationsLessZero =
        graph.durations
            .filter(dur => parseInt(dur.number) < 0)
            .map(dur => dur.id);

    // every resource that is not a number or less than 0
    let ressourcesNotNumbers =
        graph.resources
            .filter(res => isNaN(parseInt(res.number)))
            .map(res => res.id);

    // every resource that is less than 0
    let ressourcesLessZero =
        graph.resources
            .filter(res => parseInt(res.number) < 0)
            .map(res => res.id);

    return {
        arrows: arrowWeightsNotNumbers.concat(unconnectedArrow),
        durations: durationsNotNumbers.concat(durationsLessZero),
        resources: ressourcesNotNumbers.concat(ressourcesLessZero)
    };

}



function createDistancematrixfromConnections(graph){

    let distanceMatrix = [];
    // init distance matrix
    for (let i = 0; i < graph.countNodes; i++) {
        let row = [];
        for (let j = 0; j < graph.countNodes; j++) {
            if(i == j){
                row.push(0);
            } else {
                row.push(Infinity);
            }
        }
        distanceMatrix.push(row);
    }

    graph.connections.forEach(con => {
        let i = con.start;
        let j = con.end;
        distanceMatrix[i][j] = parseInt(graph.weights.find(w => w.arrow == con.arrow).number);
    });


    // labelCorrectingAlgorithm(graph, distanceMatrix, 0);
    return distanceMatrix;

}


function labelCorrectingAlgorithm(graph, distanceMatrix, node){

    let d = [];
    let p = [];
    let Q = [];
    Q.push(node);

    // Step 1
    for (let i = 0; i < distanceMatrix.length; i++) {
        if(i == node){
            d[i] = 0;
            p[i] = node;
        } else {
            d[i] = Number.NEGATIVE_INFINITY;
            p[i] = 0;
        }
    }

    // Step 2
    while(Q.length != 0){
        let node = Q.shift();

        graph.findNeigboursOfNode(node).forEach(neighbour => {
            let newDistance = d[node] + distanceMatrix[node][neighbour];
            if (d[neighbour] < newDistance){
                d[neighbour] = newDistance;
                p[neighbour] = node;
                if (!Q.includes(neighbour)){
                    Q.push(neighbour);
                }
            } 
        });

    }

    return d;
    
}


export function createTable(graph){

    let distanceMatrix = createDistancematrixfromConnections(graph);


    let ES = [];
    let EC = [];
    let LS = [];
    let LC = [];
    let TF = [];
    let EFF = [];
    let LFF = [];

    for (let i = 0; i < distanceMatrix.length; i++) {
        let val = labelCorrectingAlgorithm(graph, distanceMatrix, 0)[i];
        ES.push(val);
        EC.push(val + parseInt(graph.getDurationOfNode(i).number));
        let valL = labelCorrectingAlgorithm(graph, distanceMatrix, i)[0] * -1;
        LS.push(valL);
        LC.push(valL + parseInt(graph.getDurationOfNode(i).number));
        TF.push(LS[i] - ES[i]);
    }


    for (let i = 0; i < distanceMatrix.length; i++) {
        // EFF
        let all = graph
            .findNeigboursOfNode(i)
            .map(node => ES[node] - distanceMatrix[i][node]);
        EFF.push(Math.min(...all) - ES[i]);

        // LFFi
        all = graph
            .findPredecessorOfNode(i)
            .map(node => LS[node] + distanceMatrix[node][i])
        LFF.push(LS[i] - Math.max(...all)); 
    }

    return { ES, EC, LS, LC, TF, EFF, LFF};

}

// Label Correcting Algorithm
// Determine longest paths from each node
function labelCorrectingAlgorithmLCA(graph, distanceMatrix, node){   // Function declaration

    let d = [];
    let p = [];
    let Q = [];   
    Q.push(node);
    let iteration = 0;   // Declaration of variables
    let iterationQ = [];   
    let iterationD = [];
    let iterationP = [];   // Arrays to store the variable values in each iteration

    // Step 1 - Initialization
    for (let i = 0; i < distanceMatrix.length; i++) {
        if(i == node){
            d[i] = 0;
            p[i] = node;
        } else {
            d[i] = Number.NEGATIVE_INFINITY;
            p[i] = -1;
        }
    }

    // Step 2 - Main phase
    while(Q.length != 0){   //Execution until Q = {}
      
        // Assign Q, P and D to respective iterations
        // slice() --> Shallow copy of array
        iterationQ[iteration] = Q.slice();
        iterationD[iteration] = d.slice();
        iterationP[iteration] = p.slice();
        
        let node = Q.shift();   // shift() --> Removes the first element of the array

        // Determination of neighboring nodes and distances
        graph.findNeigboursOfNode(node).forEach(neighbour => {
            let newDistance = d[node] + distanceMatrix[node][neighbour];
            if (d[neighbour] < newDistance){
                d[neighbour] = newDistance;
                p[neighbour] = node;
                if (!Q.includes(neighbour)){
                    Q.push(neighbour);   // Update Q
                }
                
            } 
            
        });
        iteration++   // Enumeration of iterations
        
    }
    return { iterationQ, iterationD, iterationP };   // Output of variable values for each iteration
}

// Function to run the algorithm and generate a result table
export function createTableLCA(graph){

    let distanceMatrix = createDistancematrixfromConnections(graph);   // Executing the distance matrix for the graph
   
    let LCATable = [];

    for (let i = 0; i < distanceMatrix.length; i++) {
        let val = labelCorrectingAlgorithmLCA(graph, distanceMatrix, i);  //  Performing the LCA for each node
        LCATable.push(val);   
    }
    return { LCATable };   
}

// Priority Rule Based Method
// Determining the distances
function labelCorrectingAlgorithmPRBM(graph, distanceMatrix, node){

    let d = [];
    let p = [];
    let Q = [];
    Q.push(node);

    // Step 1
    for (let i = 0; i < distanceMatrix.length; i++) {
        if(i == node){
            d[i] = 0;
            p[i] = node;
        } else {
            d[i] = Number.NEGATIVE_INFINITY;
            p[i] = 0;
        }
    }

    // Step 2
    while(Q.length != 0){
        let node = Q.shift();

        graph.findNeigboursOfNode(node).forEach(neighbour => {
            let newDistance = d[node] + distanceMatrix[node][neighbour];
            if (d[neighbour] < newDistance){
                d[neighbour] = newDistance;
                p[neighbour] = node;
                if (!Q.includes(neighbour)){
                    Q.push(neighbour);
                }
            } 
        });
    }

    return d;
    
}

export function createTablesPRBM(graph,objectiveFunction,priorityRule,objectiveFunctionData){
    let tables = [];

    let distanceMatrix = createDistancematrixfromConnections(graph);

    let ES = [];
    let LS = [];
    let TF = [];
    let RP = [];
    let RiP = [];

    for (let i = 0; i < distanceMatrix.length; i++) {
        let val = labelCorrectingAlgorithmPRBM(graph, distanceMatrix, 0)[i];
        ES.push(val);
        let valL = labelCorrectingAlgorithmPRBM(graph, distanceMatrix, i)[0] * -1;
        LS.push(valL);
        TF.push(LS[i] - ES[i]);
        RP.push(parseInt(graph.getResourceOfNode(i).number));
        RiP.push(parseInt(graph.getDurationOfNode(i).number) * parseInt(graph.getResourceOfNode(i).number));
    }

    let scheduledActivities = scheduleActivities(graph, {ES, LS});
    let basicMetrics = {ES, LS, TF, RP, RiP}
    let nextStartTimes = JSON.parse(JSON.stringify({ES:basicMetrics.ES,LS:basicMetrics.LS}));
    // Choosing next node by priority Rule  
    let nextNode;
    switch (priorityRule) {
        case "GRD":
            nextNode = chooseNextNodeGRD(scheduledActivities.c_quer, RiP);
            break;
        case "GRDT":
            nextNode = chooseNextNodeGRDt(scheduledActivities.c_quer, RP);
            break;
        case "LST":
            nextNode = chooseNextNodeLST(scheduledActivities.c_quer, LS);
            break;
        case "MST":
            nextNode = chooseNextNodeMST(scheduledActivities.c_quer, TF);
            break;
        default:
            console.error("No priorityRule " +priorityRule+ " defined!")
            return tables;
    }

    tables.push({basicMetrics,scheduledActivities,nextNode}); // initialization
    // Main Step
    while(typeof nextNode !== "undefined") {
        const ressourcePerTimeUnit = getRessourcePerTimeUnit(graph, scheduledActivities.c, scheduledActivities.SC, RP, LS);
        
        let decisionPoints
        switch (objectiveFunction) {
            case "RI":
                decisionPoints = getDecisionPoints(graph, scheduledActivities.c, scheduledActivities.SC, nextNode, nextStartTimes );
                break;
            case "RD":
            case "RL":
                decisionPoints = getDecisionPointsForRDandRL(graph, scheduledActivities.c, scheduledActivities.SC, nextNode, nextStartTimes );
                break;
            default:
                console.error("No objectiveFunction " +objectiveFunction+ " defined!")
                return tables;
        }
        
        let ressourceData
        switch (objectiveFunction) {
            case "RI":
                ressourceData = ressourceInvestment(graph,ressourcePerTimeUnit,nextNode,RP,decisionPoints,objectiveFunctionData.cp);
                break;
            case "RD":
                ressourceData = ressourceDeviation(graph,ressourcePerTimeUnit,nextNode,RP,decisionPoints,objectiveFunctionData.cd,objectiveFunctionData.y);
                break;
            case "RL":
                ressourceData = ressourceLeveling(graph,ressourcePerTimeUnit,nextNode,RP,decisionPoints);
                break;
            default:
                console.error("No objectiveFunction " +objectiveFunction+ " defined!")
                return tables;
        }
        let startTimeNextNode = ressourceData.startTimeNextNode;
        let additionalCosts = ressourceData.lowestAdditionalCosts;

        nextStartTimes.ES[nextNode] = startTimeNextNode;
        nextStartTimes.LS[nextNode] = startTimeNextNode;
        
        //ES und LS update
        let longestPaths_di = labelCorrectingAlgorithmLCA(graph,distanceMatrix,nextNode).iterationD.pop(); //longest Path from next node to j

        for (let j = 0; j < graph.countNodes; j++) {
            let longestPath_dji = labelCorrectingAlgorithmLCA(graph,distanceMatrix,j).iterationD.pop(); //longest Path from j to next node
            nextStartTimes.ES[j] = Math.max(nextStartTimes.ES[j],nextStartTimes.ES[nextNode]+longestPaths_di[j]);
            nextStartTimes.LS[j] = Math.min(nextStartTimes.LS[j],nextStartTimes.LS[nextNode]-longestPath_dji[nextNode]);
        }
        
        scheduledActivities = scheduleActivities(graph, nextStartTimes);
        switch (priorityRule) {
            case "GRD":
                nextNode = chooseNextNodeGRD(scheduledActivities.c_quer, RiP);
                break;
            case "GRDT":
                nextNode = chooseNextNodeGRDt(scheduledActivities.c_quer, RP);
                break;
            case "LST":
                nextNode = chooseNextNodeLST(scheduledActivities.c_quer, LS);
                break;
            case "MST":
                nextNode = chooseNextNodeMST(scheduledActivities.c_quer, TF);
                break;
            default:
                console.error("No priorityRule " +priorityRule+ " defined!")
                return tables;
        }

        tables.push({basicMetrics:JSON.parse(JSON.stringify(nextStartTimes)),scheduledActivities,nextNode,decisionPoints,startTimeNextNode,additionalCosts})
    }
    console.log("Finished, no next node");
    const finalTable = tables[tables.length - 1];
    const finalRessourcePerTimeUnit = getRessourcePerTimeUnit(graph, finalTable.scheduledActivities.c, finalTable.scheduledActivities.SC, basicMetrics.RP, basicMetrics.LS);
    // Determination of the objective function value for the optimal Schedule
    let objectiveFunctionValue
    switch (objectiveFunction) {
        case "RI":
            objectiveFunctionValue = calculateOFVResourceInvestment(finalRessourcePerTimeUnit,objectiveFunctionData.cp);
            break;
        case "RD":
            objectiveFunctionValue = calculateOFVResourceDeviation(finalRessourcePerTimeUnit,objectiveFunctionData.cd,objectiveFunctionData.y);
            break;
        case "RL":
            objectiveFunctionValue = calculateOFVResourceLeveling(finalRessourcePerTimeUnit);
            break;
        default:
            console.error("No objectiveFunction " +objectiveFunction+ " defined!")
            return tables;
    }
    
    finalTable.objectiveFunctionValue = objectiveFunctionValue;

    return tables;
}

// Update C & C(Quer)
function scheduleActivities(aGraph, aStarttimes) {
    let c = [];
    let c_quer = [];
    let SC = [];
    for (let i = 0; i < aGraph.countNodes; i++) {
        if (aStarttimes.ES[i] == aStarttimes.LS[i]) {
            c.push(i);
            SC.push(aStarttimes.ES[i]);
        }
        else {
            c_quer.push(i);
        }
    }
    return { c, c_quer, SC };
}

// Prioritätsregeln
// GRD-Rule
function chooseNextNodeGRD(aC_quer, aRiP) {
    let nextNode;
    let GRD = -1;
    for (const i of aC_quer) {
        if (aRiP[i] > GRD) {
            GRD = aRiP[i];
            nextNode = i;
        }
    }
    console.log("NextNodeGRD",nextNode);
    return nextNode;
}
// GRDt-Rule
function chooseNextNodeGRDt(aC_quer, aRP) {
    let nextNode;
    let GRDt = -1;
    for (const i of aC_quer) {
        if (aRP[i] > GRDt) {
            GRDt = aRP[i];
            nextNode = i;
        }
    }
    console.log("NextNodeGRDt",nextNode);
    return nextNode;
}
// LST-Rule
function chooseNextNodeLST(aC_quer, aLS) {
    let nextNode = aC_quer[0];
    let SLST = aLS[nextNode];
    for (const i of aC_quer) {
        if (aLS[i] < SLST) {
            SLST = aLS[i];
            nextNode = i;
        } 
    }
    console.log("NextNodeLST",nextNode);
    return nextNode;
}
// MST-Rule
function chooseNextNodeMST(aC_quer, aTF) {
    let nextNode = aC_quer[0];
    let STF = aTF[nextNode];
    for (const i of aC_quer) {
        if (aTF[i] < STF) {
            STF = aTF[i];
            nextNode = i;
        } 
    }
    console.log("NextNodeMST",nextNode);
    return nextNode;
}

function getMaxValue(aArray) {
    let maxValue = 0;
    
    for (const value of aArray) {
        if (value > maxValue) {
            maxValue = value;
        }
    }
    return maxValue;
}

//Resource Investmentproblem
function ressourceInvestment(aGraph, aRessourcePerTimeUnit, aNextNode, aRP, aDecisionPoints, CP) {
    let lowestAdditionalCosts;
    let startTimeNextNode;

    const maxResource = getMaxResourceC(aRessourcePerTimeUnit);

    for (const DP of aDecisionPoints) {
        let ressourcePerTimeUnit_nextNode = aRessourcePerTimeUnit.slice();
        
            for(let j = 0 ; j < parseInt(aGraph.getDurationOfNode(aNextNode).number); j++) {
                ressourcePerTimeUnit_nextNode[DP+j] += aRP[aNextNode];
            }
            console.log("ressourcePerTimeUnit_nextNode",ressourcePerTimeUnit_nextNode);
         
        let maxResourceWithNextNode = getMaxResourceC(ressourcePerTimeUnit_nextNode);
        console.log("maxRessourceWithNextNode",aNextNode,DP,maxResourceWithNextNode);

        const additionalCosts = CP * (maxResourceWithNextNode - maxResource)
        if (typeof lowestAdditionalCosts === "undefined" || additionalCosts <= lowestAdditionalCosts) {
            lowestAdditionalCosts = additionalCosts;
            startTimeNextNode = DP;
        }
    }

    console.log("lowestAddCost,startTimeNextNode",lowestAdditionalCosts,startTimeNextNode)
    return {startTimeNextNode,lowestAdditionalCosts};
}

//Resource Deviation Problem
function ressourceDeviation(aGraph, aRessourcePerTimeUnit, aNextNode, aRP, aDecisionPoints, CD, Y) {

    let lowestAdditionalCosts;
    let startTimeNextNode;

    for (const DP of aDecisionPoints) {
        let ressourcePerTimeUnit_nextNode = aRessourcePerTimeUnit.slice();
    
        for(let j = 0 ; j < parseInt(aGraph.getDurationOfNode(aNextNode).number); j++) {
            ressourcePerTimeUnit_nextNode[DP+j] += aRP[aNextNode];
        }
        console.log("ressourcePerTimeUnit_nextNode",ressourcePerTimeUnit_nextNode);

        let resourceDiffPerTimeUnit = [];
        let additionalCosts = 0;
        
        for(let i = 0; i < ressourcePerTimeUnit_nextNode.length; i++) {
            resourceDiffPerTimeUnit[i] = Math.max((ressourcePerTimeUnit_nextNode[i] - Y),0)  - Math.max((aRessourcePerTimeUnit[i] - Y),0);
            additionalCosts += resourceDiffPerTimeUnit[i];
        }
        
        console.log("resourceDiffPerTimeUnit",resourceDiffPerTimeUnit);
        additionalCosts =  CD * additionalCosts;

        if (typeof lowestAdditionalCosts === "undefined" || additionalCosts <= lowestAdditionalCosts) {
            lowestAdditionalCosts = additionalCosts;
            startTimeNextNode = DP;
        }
    }

    console.log("ressourceDeviation() - lowestAddCost,startTimeNextNode",lowestAdditionalCosts,startTimeNextNode)
    return {startTimeNextNode,lowestAdditionalCosts};
}


//Resource Leveling Problem
function ressourceLeveling(aGraph, aRessourcePerTimeUnit, aNextNode, aRP, aDecisionPoints) {
    let lowestAdditionalCosts;
    let startTimeNextNode;

    for (const DP of aDecisionPoints) {
        let ressourcePerTimeUnit_nextNode = aRessourcePerTimeUnit.slice();
    
        for(let j = 0 ; j < parseInt(aGraph.getDurationOfNode(aNextNode).number); j++) {
            ressourcePerTimeUnit_nextNode[DP+j] += aRP[aNextNode];
        }
        console.log("ressourcePerTimeUnit_nextNode",ressourcePerTimeUnit_nextNode);

        let resourceQuadDiffPerTimeUnit = [];
        let additionalCosts = 0;
        
        for(let i = 0; i < ressourcePerTimeUnit_nextNode.length; i++) {
            resourceQuadDiffPerTimeUnit[i] = (ressourcePerTimeUnit_nextNode[i] * ressourcePerTimeUnit_nextNode[i]) - (aRessourcePerTimeUnit[i] * aRessourcePerTimeUnit[i]);
            additionalCosts += resourceQuadDiffPerTimeUnit[i];
        }
        
        console.log("resourceQuadDiffPerTimeUnit",resourceQuadDiffPerTimeUnit);
        console.log("additionalCosts",additionalCosts);

        if (typeof lowestAdditionalCosts === "undefined" || additionalCosts <= lowestAdditionalCosts) {
            lowestAdditionalCosts = additionalCosts;
            startTimeNextNode = DP;
        }
    }

    return {startTimeNextNode,lowestAdditionalCosts};
}
// Objective Function Value (RL)
function calculateOFVResourceLeveling(aRessourcePerTimeUnit) {
    let objectiveFunctionValue = 0;

        let resourceQuadPerTimeUnit = [];
        
        for(let i = 0; i < aRessourcePerTimeUnit.length; i++) {
            resourceQuadPerTimeUnit[i] = (aRessourcePerTimeUnit[i] * aRessourcePerTimeUnit[i]);
            objectiveFunctionValue += resourceQuadPerTimeUnit[i];
        }
        
    return objectiveFunctionValue;
}
// Objective Function Value (RD)
function calculateOFVResourceDeviation(aRessourcePerTimeUnit,CD,Y) {
    let objectiveFunctionValue = 0;

    let resourceDiffPerTimeUnit = [];

    for(let i = 0; i < aRessourcePerTimeUnit.length; i++) {
        resourceDiffPerTimeUnit[i] = Math.max((aRessourcePerTimeUnit[i] - Y),0);
        objectiveFunctionValue += resourceDiffPerTimeUnit[i];
    }

    objectiveFunctionValue *= CD;
        
    return objectiveFunctionValue;
}
// Objective Function Value (RI)
function calculateOFVResourceInvestment(aRessourcePerTimeUnit,CP) {
    
    const maxResource = getMaxResourceC(aRessourcePerTimeUnit);
    
    const objectiveFunctionValue = CP * maxResource;
        
    return objectiveFunctionValue;
}

// Determination of the resources per time unit for C
function getRessourcePerTimeUnit(aGraph, aC, aSC, aRP, aLS) {

    let ressourcePerTimeUnit = [];
    for(let i = 0; i < getMaxValue(aLS); i++) {
        ressourcePerTimeUnit[i] = 0;
    }

    for (let nodeIndex = 0; nodeIndex < aC.length; nodeIndex++) {
        for (let t = 0 ; t < parseInt(aGraph.getDurationOfNode(aC[nodeIndex]).number); t++) {
            ressourcePerTimeUnit[aSC[nodeIndex]+t] += aRP[aC[nodeIndex]];
        }        
    }
    console.log("ressourcePerTimeUnit",ressourcePerTimeUnit);
    return ressourcePerTimeUnit;
}
// Determination of the resources per time unit per node for C
export function getRessourcePerTimeUnitPerNode(aGraph, aC, aSC, aRP, aLS) {

    console.log("agraph",aGraph);
    console.log("aC",aC);
    console.log("aSC",aSC);
    console.log("aRP",aRP);
    console.log("aLS",aLS);
  
    let ressourcePerTimeUnitPerNode = [];
    let baseRssourcePerTimeUnit = [];
    for(let i = 0; i < getMaxValue(aLS); i++) {
        baseRssourcePerTimeUnit[i] = 0;
    }

    for (let nodeIndex = 0; nodeIndex < aC.length; nodeIndex++) {
      let ressourcePerTimeUnit = baseRssourcePerTimeUnit.slice();
        for (let t = 0 ; t < parseInt(aGraph.getDurationOfNode(aC[nodeIndex]).number); t++) {
          ressourcePerTimeUnit[aSC[nodeIndex]+t] += aRP[aC[nodeIndex]];
        }        
        ressourcePerTimeUnitPerNode[aC[nodeIndex]] =ressourcePerTimeUnit;
    }
    console.log("RessourcePerTimeUnitPerNode",ressourcePerTimeUnitPerNode);
    return ressourcePerTimeUnitPerNode;
}

// Determination of max resource 
function getMaxResourceC(aRessourcePerTimeUnit) {
    
    let maxResource = 0;
    for (let i = 0; i < aRessourcePerTimeUnit.length; i++) {
        if (aRessourcePerTimeUnit[i] > maxResource) {
            maxResource = aRessourcePerTimeUnit[i];
        }
    }
    console.log("maxResource",maxResource);
    return maxResource;
}


// Decicion Points for Resource Investment Problem
function getDecisionPoints (aGraph, aC, aSC, aNextNode, aStarttimes) {
    let decisionPoints = [aStarttimes.ES[aNextNode]];

    for (let i = 0; i < aC.length; i++) {
        const DP = aSC[i] + parseInt(aGraph.getDurationOfNode(aC[i]).number)
        if (DP > aStarttimes.ES[aNextNode] && DP <= aStarttimes.LS[aNextNode]){
            decisionPoints.push(DP);
        }
    }
    console.log("DP:", decisionPoints);
    return decisionPoints;
}

// Decicion Points for Resource Deviation and Resource Leveling Problem
function getDecisionPointsForRDandRL (aGraph, aC, aSC, aNextNode, aStarttimes) {
    let decisionPoints = [aStarttimes.ES[aNextNode],aStarttimes.LS[aNextNode]];

    for (let i = 0; i < aC.length; i++) {
        const DP = aSC[i] + parseInt(aGraph.getDurationOfNode(aC[i]).number)
        if (DP > aStarttimes.ES[aNextNode] && DP < aStarttimes.LS[aNextNode]){
            decisionPoints.push(DP);
        }
    }

    for(let t = aStarttimes.ES[aNextNode] ; t < aStarttimes.LS[aNextNode] ; t++) {
        const DP = t + parseInt(aGraph.getDurationOfNode(aNextNode).number);
        for (let i = 0; i < aC.length; i++) {
            if(DP == aSC[i] && !decisionPoints.includes(t)) {
                decisionPoints.push(t);
            }
        }
    }

    console.log("DP:", decisionPoints);
    return decisionPoints;
}

